import React from 'react';
import Layout from '../../components/layout';
import Page from '../../components/page';

const PageTemplate = (data) => {
  // console.log();
  if (!data.pageContext.product) return false;

  return (
    <Layout>
      <div className={data.pageContext.product.url.replace('/', '')}>
        <Page content={data.pageContext.product.content}></Page>{' '}
      </div>
    </Layout>
  );
};

export default PageTemplate;
